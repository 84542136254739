import {
  AppBar,
  Button,
  makeStyles,
  Toolbar,
  Typography,
} from "@material-ui/core";

import ToggleButtonGroup from "@material-ui/lab/ToggleButtonGroup";
import ToggleButton from "@material-ui/lab/ToggleButton";
import { useHistory, useLocation } from "react-router-dom";
import codyseum from "../images/codyseumT.png";
import bsc from "../images/bsc.svg";
import matic from "../images/matic.svg";
import avax from "../images/avax.svg";

import cryptokenT from "../images/cryptokenT.png";
import LanguageSelect from "./LanguageSelect";
import "../components/I18NextConf";
import { Trans, useTranslation } from "react-i18next";
import React, { useEffect } from "react";
import { stateStore } from "./stateStore.js";
import chainConfig from "../config/chainConfig";
import Contracts from '../config/Contracts'
import Web3 from "web3";
// import connect from "./Connect"

const useStyles = makeStyles((theme) => {
  return {
    page: {
      background: "#27262c",
      width: "100%",
      padding: theme.spacing(3),
    },

    footer: {
      position: "absolute",
      bottom: 5,
      left: "45%",
      zIndex: 99,
    },
    appbar: {
      //   width: `calc(100% - ${drawerWidth}px)`,
      backgroundColor: "#111112",
    },

    root: {
      display: "flex",
      position: "relative",
    },
    active: {
      background: "#7d7a8c",
    },
    title: {
      padding: theme.spacing(2),
    },

    toolbar: theme.mixins.toolbar,
    Apptitle: {
      //   flexGrow: 1,
      textJustify: "center",
      marginLeft: "auto",
      marginRight: "auto",
    },
    avatar: {
      marginLeft: theme.spacing(2),
    },

    social: {
      position: "fixed",
      bottom: 0,
      padding: 20,
      display: "flex",
    },
    social_space: {
      marginRight: 20,
    },

    logoCody: {
      width: "auto",
      height: 50,
      marginTop: 0,
      display: "flex",
      ['@media (max-width:810px)']: { display:"none" }
    },

 

    AppLogo: {
      width: "auto",
      height: 30,
    },
    lang: {
      marginLeft: 20,
    },
    tButton: {
      width: 50,
    },
    connect: {
      marginLeft: 8,
    },
  };
});

const Layout = ({ children }) => {
  const classes = useStyles();
  const history = useHistory();
  const location = useLocation();
  const meta = stateStore.useState((s) => s.meta);
  const web3 = stateStore.useState((s) => s.web3);
  let userAccount;
  const chain = stateStore.useState((s) => s.chain);
  const walletBalance = stateStore.useState((s) => s.walletBalance);
  const chainInfo = chainConfig[`${chain}`];

  async function connect() {
    if (window.ethereum) {
      const tampon = new Web3(window.ethereum);
      stateStore.update((s) => {
        s.web3 = tampon;
      });

      try {
        // Request account access if needed
        await window.ethereum.enable();
        userAccount = (await web3.eth.getAccounts())[0];
        stateStore.update((s) => {
          s.meta = userAccount;
        });
      } catch (error) {
        // User denied account access...
      }
    }



     await window.ethereum
        .request(
          chainInfo
        )
        .catch((error) => {
          console.log(error);
        });



   // change network
    await window.ethereum.request({
      method: "wallet_switchEthereumChain",
      params: [{ chainId: "0x89" }], // chainId must be in hexadecimal numbers
    });


    const resultWallet = web3.utils.BN(web3.eth.getBalance(meta));
    const etherBalance = web3.utils.fromWei(resultWallet);
    stateStore.update((s) => {
      s.walletBalance = etherBalance;
    
    });
    
    console.log("the wallet balance is "+walletBalance);
  }

  


  const balance = async () => {
    const resultWallet = web3.utils.BN(web3.eth.getBalance(meta));
const etherBalance = web3.utils.fromWei(resultWallet);
stateStore.update((s) => {
  s.walletBalance = etherBalance;

});

console.log("the wallet balance is "+walletBalance);
}

// useEffect(() => {
// const interval = setInterval(() => {
//   connect();
//   // balance();
//   console.log('This will run every second!');
// }, 3000);
// return () => clearInterval(interval);
// }, []);


  const handleConnect = async () => {
    await connect();
  };

  const handleChain = (event, newChain) => {
    stateStore.update((s) => {
      s.chain = newChain;
    });
    const chainInfo = chainConfig[`${newChain}`];

    window.ethereum.request(chainInfo).catch((error) => {
      console.log(error);
    });
  };

  useEffect(async () => {
    await connect();
  }, []);

  return (
    <div className={classes.root}>
      {/* App BAR */}
      <AppBar className={classes.appbar} elevation={0}>
        <Toolbar>
          <div className={classes.logoCody}>
            <a href="https://www.codyseum.com/" target="blank">
              <img src={codyseum} alt="logo cody" />
            </a>
          </div>
          
          <Typography
            className={classes.Apptitle}
            variant="h5"
            color="secondary"
          >
            My Patent 
          </Typography>
          
          <ToggleButtonGroup
            value={chain}
            exclusive
            onChange={handleChain}
            aria-label="Blockchain"
          >
            {/* <ToggleButton
              value="bsc"
              aria-label="bsc choosed"
              className={classes.tButton}
            >
              <img src={bsc} alt="logo bsc" className={classes.AppLogo} />
            </ToggleButton>

            <ToggleButton
              value="avax"
              aria-label="Avax choosed"
              className={classes.tButton}
            >
              <img src={avax} alt="logo Avax" className={classes.AppLogo} />
            </ToggleButton> */}
            <ToggleButton
              value="matic"
              aria-label="matic choosed"
              className={classes.tButton}
            >
              <img src={matic} alt="logo Polygon" className={classes.AppLogo} />
            </ToggleButton>
          </ToggleButtonGroup>

          {/* <div className={classes.lang}>
            <LanguageSelect />
          </div> */}
          <div className={classes.connect}>
            {meta ? (
              <Button variant="contained" size="small" color="primary" disabled>
                <Typography variant="body2" fontSize="small" style={{color:"green"}}>
                  Connected
                </Typography>
              </Button>
            ) : (
              <Button
                variant="contained"
                size="small"
                color="primary"
                onClick={handleConnect}
              >
                <Typography variant="body2" fontSize="small">
                  Connect
                </Typography>
              </Button>
            )}
          </div>
        </Toolbar>
      </AppBar>

      <div className={classes.page}>
        <div className={classes.toolbar}></div>
        {children}
      </div>

      {/* <div className={classes.footer}>


        <Typography variant="h8" color="secondary">
          Made with love by <Link href="https://codyseum.com">Codyseum</Link>{" "}
          all rights reserved
        </Typography>
      </div> */}
    </div>
  );
};

export default Layout;
