import { useState, useEffect, useRef } from "react";
import styles from "./styles/app.module.css";
import { create } from "ipfs-http-client";
import { Buffer } from "buffer";
import { stateStore } from "./components/stateStore.js";
import NewERC721ABI from "./contracts/ABI/NewERC721ABI.js";
import Layout from "./components/Layout";
import logoPatent from "./images/3.png";
import {
    CircularProgress,
    createTheme,
    ThemeProvider,
} from "@material-ui/core";
import Box from "@material-ui/core/Box";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import { Grid, IconButton, Typography, Button } from "@material-ui/core";
import { Facebook, Twitter, LinkedIn } from "@material-ui/icons";
import { useReactToPrint } from "react-to-print";

export default function Home() {
    const [file, setFile] = useState(null);
    const [urlArrMeta, setUrlArrMeta] = useState([]);
    const [urlFile, setUrlFile] = useState(0);
    const [urlMeta, setUrlMeta] = useState(0);
    const imgUrl = "https://ipfs.io/ipfs/QmUeAXhiwFWHYzYEmJC6gv5ik2VFVH1iVo1m8BoyJ3KmMh"
    const [minting, setMinting] = useState(false);
    const [jsonDatas, setJsonDatas] = useState({});
    const [nftNumber, setnftNumber] = useState(0);
    const [loadingFile, setLoadingFile] = useState(false);

    var today = new Date();
    var date =
        today.getFullYear() +
        "-" +
        (today.getMonth() + 1) +
        "-" +
        today.getDate();
    var time =
        today.getHours() + ":" + today.getMinutes() + ":" + today.getSeconds();
    var dateTime = date + " " + time;
    const [NFTcreated, setNFTcreated] = useState(false);
    const [checkBalance, setCheckBalance] = useState(0);
    const meta = stateStore.useState((s) => s.meta);
    const web3 = stateStore.useState((s) => s.web3);
    const walletBalance = stateStore.useState((s) => s.walletBalance);
    const [dataProgress, setDataProgress] = useState(0);
    const priceUse = "0";
    // const contractERC721 = "0xA14434ef68eCE2663fef937F003cCf6d18FEf4Ed";  -> adresse rinkeby
    const contractERC721 = "0xD87c8b89A517e0dd88D700733d774ADc5b2B1b82";
    const projectId = "24Hu4vTntPMSrS7qdSdZxkgsrPm";
    const projectSecret = "4515b4ba60b4110de36b3a195f88f298";
    const auth =
        "Basic " +
        Buffer.from(projectId + ":" + projectSecret).toString("base64");
    const client = create({
        host: "ipfs.infura.io",
        port: 5001,
        protocol: "https",
        headers: {
            authorization: auth,
        },
    });

    const theme = createTheme({
        palette: {
            primary: {
                main: "#01def8",
            },
            secondary: {
                main: "#ffffff",
            },
            background: {
                paper: "#27262c",
                default: "#27262c",
            },

            text: {
                primary: "#348ef2",
            },
            root: {
                "& .MuiFormLabel-root": {
                    color: "#27262c",
                },
            },
        },

        typography: {
            fontFamily: "Audiowide",
            fontWeightLight: 400,
            fontWeightRegular: 500,
            fontWeightMedium: 600,
            fontWeightBold: 700,
        },
    });

    const readNft = async () => {
        const contract = new web3.eth.Contract(NewERC721ABI, contractERC721);
        const res = await contract.methods.balanceOf(meta).call();
        setnftNumber(res);
        console.log("you have deposited " + nftNumber + "Patents");
    };

    // const balance = async () => {
    //       const resultWallet = web3.utils.BN(web3.eth.getBalance(meta));
    //   const etherBalance = web3.utils.fromWei(resultWallet);
    //   stateStore.update((s) => {
    //     s.walletBalance = etherBalance;

    //   });

    //   console.log("the wallet balance is "+walletBalance);
    // }

    // useEffect(() => {
    //   const interval = setInterval(() => {
    //     balance();
    //     console.log('This will run every second!');
    //   }, 3000);
    //   return () => clearInterval(interval);
    // }, []);

    const handleSubmit = async (e) => {
        e.preventDefault();
        // await connect();
        try {
            //pin file ou folder
            setLoadingFile(true);
            const created = await client.add(file);
            client.pin.add(`${created.path}`).then((res) => console.log(res));

            const urlFile = `https://ipfs.io/ipfs/${created.path}`;
            // const oldURL = `https://ipfs.infura.io/ipfs/${created.path}`;
            setUrlFile(urlFile);

            // create a Json from entries and link

            const data = new FormData(e.target);
            const formJSON = Object.fromEntries(data.entries());
            formJSON.FileUrl = urlFile;
            formJSON.Image = imgUrl;
            const JSONForm = JSON.stringify(formJSON);
            console.log("data registred is " + JSONForm);
            setJsonDatas(JSONForm);

            setLoadingFile(false);

            //pin metadata
            const createdMeta = await client.add(JSONForm);
            client.pin
                .add(`${createdMeta.path}`)
                .then((res) => console.log(res));
            const urlMeta = `${createdMeta.path}`;
            // const OldMetaURL = `https://ipfs.infura.io/ipfs/${createdMeta.path}`;
            setUrlMeta(urlMeta);
            console.log("urlMeta " + urlMeta);
            setUrlArrMeta((prev) => [...prev, urlMeta]);

            // create NFT from Json metadata

            const contract = new web3.eth.Contract(
                NewERC721ABI,
                contractERC721
            );
            setMinting(true);
            const result = await contract.methods
                .createItem(createdMeta.path, urlMeta)
                .send({
                    from: meta,
                    gasPrice: "100000000000",
                    value: web3.utils.toWei(`${priceUse}`, "ether"),
                });
            console.log("deployed contract is " + result);
            setNFTcreated(true);
            setMinting(false);
        } catch (error) {
            console.log("the error " + error.message);
        }
    };

    const retrieveFile = async (e) => {
        const data = e.target.files[0];
        const reader = new window.FileReader();
        reader.readAsArrayBuffer(data);
        reader.onloadend = () => {
            console.log("Buffer data: ", Buffer(reader.result));
            setFile(Buffer(reader.result));
        };

        e.preventDefault();
    };

    const componentRef = useRef();
    const handlePrint = useReactToPrint({
        content: () => componentRef.current,
    });

    const handleAgain = () => {
        setNFTcreated(false);
    };
  
    // const contract = new web3.eth.Contract(NewERC721ABI, contractERC721);
    // const resultWalletOwner = await contract.methods
    //   .walletOfOwner(meta)
    //   .call({ from: meta });
    // stateStore.update((s) => {
    //   s.walletOwner = resultWalletOwner;


    return (
        <ThemeProvider theme={theme}>
            <Router>
                <Layout>
                    <Switch>
                        <Route exact path="/">
                            <div className={styles.container}>
                                <main className={styles.main}>
                                    <div>
                                        <img
                                            src={logoPatent}
                                            className={styles.patentLogo}
                                            alt="logo patent"
                                        />
                                    </div>
                                    <Typography
                                        color="secondary"
                                        variant="h4"
                                        className={styles.title}>
                                        Welcome to My Patent
                                    </Typography>

                                    <p className={styles.description}>
                                        The first website allowing you to
                                        deposit your project on the blockchain
                                        to prove to the world you had the idea
                                        first.
                                    </p>

                                    <p>
                                        You can deposit anything from a logo to
                                        a complete project, all needs to be in a
                                        single archive or file (.zip, .rar...)
                                    </p>

                                    {!meta ? (
                                        <div className={styles.card}>
                                            <h2>
                                                You need to have Metamask and
                                                some Matic to use our services,
                                                do you have it ?
                                            </h2>
                                            <p>
                                                If you have it and have enough
                                                Matic,{" "}
                                                <strong>
                                                    hit the connect Button
                                                </strong>{" "}
                                                on top right corner
                                            </p>
                                            <p>If not : </p>
                                            <Button
                                                variant="contained"
                                                color="primary"
                                                onClick={(event) =>
                                                    window.open(
                                                        "https://metamask.io/download/",
                                                        "_blank"
                                                    )
                                                }>
                                                Download
                                            </Button>{" "}
                                            <Button
                                                variant="contained"
                                                color="primary"
                                                onClick={(event) =>
                                                    window.open(
                                                        "https://www.moonpay.com/buy/matic",
                                                        "_blank"
                                                    )
                                                }>
                                                Buy Matic
                                            </Button>
                                        </div>
                                    ) : (
                                        <div />
                                    )}

                                    {/* // <<<<<<<<<<<<<<<<<<<<<<<>>>>>>>>>>>>>>>>>>>>>>>
                    // <<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<
                    // Metamask est installé on passe a la suite
                    // As tu assez de moula ?
                    // <<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<
                    // <<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<< */}

                                    <div className={styles.grid}>
                                        {meta && !NFTcreated ? (
                                            <div className={styles.card}>
                                                <h2>
                                                    Your amazing idea goes here
                                                    !
                                                </h2>
                                                <form
                                                    className="form"
                                                    onSubmit={handleSubmit}>
                                                    <label for="fichier">
                                                        Drop your archive (.zip,
                                                        .rar...) or your file
                                                        here
                                                    </label>
                                                    <br />

                                                    <input
                                                        type="file"
                                                        required
                                                        name="Your folder"
                                                        id="fichier"
                                                        label=" Drop your archive (.zip, .rar...) or your file here"
                                                        onChange={retrieveFile}
                                                    />
                                                    <br />
                                                    {loadingFile ? (
                                                        <CircularProgress />
                                                    ) : (
                                                        <div />
                                                    )}
                                                    <br />
                                                    <label for="name">
                                                        Your Name
                                                    </label>
                                                    <br />
                                                    <input
                                                        type="text"
                                                        id="name"
                                                        name="Name"
                                                        required
                                                    />
                                                    <br />
                                                    <label for="company">
                                                        Company
                                                    </label>
                                                    <br />
                                                    <input
                                                        type="text"
                                                        id="company"
                                                        name="Company"
                                                        required
                                                    />
                                                    <br />
                                                    <label for="zoneTexte">
                                                        Add comments to your
                                                        file
                                                    </label>
                                                    <br />

                                                    <textarea
                                                        id="zoneTexte"
                                                        name="Description"
                                                        required></textarea>
                                                    <br />
                                                    <br />
                                                    <Button
                                                        variant="contained"
                                                        color="primary"
                                                        type="submit"
                                                        className="btn">
                                                        Deposit your idea
                                                    </Button>
                                                </form>
                                                <br />
                                                {minting ? (
                                                    <CircularProgress />
                                                ) : (
                                                    <div />
                                                )}
                                            </div>
                                        ) : (
                                            <div />
                                        )}
                                        {/* Maintenant que tu as créé ton nft tu l'affiche  */}

                                        {NFTcreated ? (
                                            <div
                                                className={styles.card}
                                                ref={componentRef}>
                                                <h2>
                                                    Congratulations{" "}
                                                    {JSON.parse(jsonDatas).Name}
                                                </h2>
                                                <p>
                                                    You have deposited your
                                                    patent on the {dateTime} ,
                                                    your file url is{" "}
                                                    <a
                                                        target="_blank"
                                                        rel="noreferrer"
                                                        href={urlFile}>
                                                        {urlFile}
                                                    </a>
                                                    <br />
                                                    Your patent is secured on
                                                    the Polygon Blockchain
                                                </p>
                                                <br />
                                                <div>
                                                    <Button
                                                        variant="outlined"
                                                        color="primary"
                                                        onClick={handlePrint}>
                                                        Print Me !
                                                    </Button>{" "}
                                                    <Button
                                                        variant="outlined"
                                                        color="primary"
                                                        onClick={handleAgain}>
                                                        Deposit a new patent
                                                    </Button>
                                                </div>
                                            </div>
                                        ) : (
                                            <p />
                                        )}
                                    </div>
                                    {/* <<<<<<<<<<<<<<<<<<<<<<
<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<
<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<
Explanation du fonctionnement
<<<<<<<<<<<<<<<<<<<<<<<<<<
<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<
<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<  */}

                                    <div className="description">
                                        <h2>How does it work ?</h2>
                                        <p>
                                            Your file will be deposited on a
                                            IPFS network, it will be stored
                                            permanently on this network and
                                            nobody can suppress it.
                                        </p>

                                        <p>
                                            Then an NFT with the link of your
                                            file and description will be
                                            created, this NFT prooves to the
                                            world <strong>YOU </strong>have
                                            deposited the file and{" "}
                                            <strong>YOU </strong>had the idea
                                            first
                                        </p>

                                        <p>
                                            {" "}
                                            You can deposit anything, from logo
                                            to complete patent
                                        </p>

                                        <p>
                                            The NFT will stay forever in your
                                            metamask wallet if you don't send it
                                            to anyone
                                        </p>
                                    </div>
                                </main>

                                <footer className={styles.footer}>
                                    <Grid
                                        container
                                        spacing={2}
                                        className={styles.social}>
                                        <IconButton>
                                            <Twitter
                                                className={styles.social_space}
                                                color="primary"
                                                onClick={(event) =>
                                                    (window.location.href =
                                                        "https://twitter.com/codyseum")
                                                }
                                            />
                                        </IconButton>
                                        <IconButton>
                                            <LinkedIn
                                                className={styles.social_space}
                                                color="primary"
                                                onClick={(event) =>
                                                    (window.location.href =
                                                        "https://www.linkedin.com/company/codyseum")
                                                }
                                            />
                                        </IconButton>
                                        <IconButton>
                                            <Facebook
                                                className={styles.social_space}
                                                color="primary"
                                                onClick={(event) =>
                                                    (window.location.href =
                                                        "https://www.facebook.com/Codyseum-109322808229199")
                                                }
                                            />
                                        </IconButton>
                                    </Grid>
                                    <a
                                        href="https://codyseum.com"
                                        target="_blank"
                                        rel="noopener noreferrer">
                                        Powered by Codyseum
                                    </a>
                                </footer>
                            </div>
                        </Route>
                    </Switch>
                </Layout>
            </Router>
        </ThemeProvider>
    );
}
